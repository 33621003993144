import { Component, OnInit } from '@angular/core';
import { AskExpertListService } from '../../services/askexpertlist.service';
import { AskExpertList } from '../../interfaces/askexpertlist';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { BookingComponent } from '../booking/booking.component';

@Component({
  selector: 'app-ask-expert-list',
  templateUrl: './ask-expert-list.component.html',
  styleUrls: ['./ask-expert-list.component.scss'],
})
export class AskExpertListComponent implements OnInit {
  expertList: AskExpertList[] = [];
  public modalRef?: BsModalRef;
  constructor(private AskExpertListService: AskExpertListService,private modalService: BsModalService) {}

  ngOnInit(): void {
    this.getExpertList();
  }
  getExpertList() {
    this.AskExpertListService.getExpertList().subscribe((response) => {
      this.expertList = response;
    });
  }
  getImageLisk(image: string, type: string) {
    if (type == 'logo') {
      return 'https://mu-assets.s3.ap-south-1.amazonaws.com/new/expert/images/' + image;
    } else {
      return 'https://mu-assets.s3.ap-south-1.amazonaws.com/new/expert/' + image;
    }
  }

  openModal(id:any) {
   
      const config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: false,
        initialState: {
          expert_id: id
        }
      }
      this.modalRef = this.modalService.show(BookingComponent, config);
  }
}
