<div class="nav-container ">
  <nav class="bar bar-4 bar--transparent bar--absolute hidden-xs"  data-fixed-at="200">
      <div class="container-fluid">
          <div class="row">
              <div class="col-lg-3 col-md-2 col-md-offset-0 col-4">
                  <div class="bar__module">
                      <a  routerLink="">
                          <img class="logo logo-dark" alt="logo" src="assets/img/logo-light.png">
                          <img class="logo logo-light" alt="logo" src="assets/img/logo-light.png">
                      </a>
                  </div>
                  <!--end module-->
              </div>
              <div class="col-lg-4 col-lg-offset-0 col-md-5 col-md-offset-0 col-8 col-offset-2">
                  <div class="bar__module">
                    <button routerLink="/contact" class="btn btn-outline-light">Contact Us</button>
                  </div>
                  <!--end module-->
              </div>
          </div>
          <!--end of row-->
      </div>
      <!--end of container-->
  </nav>
  <!--end bar-->
</div>

<div class="main-container">
  <section class="imageblock  feature-large height-100">
      <div class="imageblock__content col-lg-5 col-md-4  hidden-xs">
          <div class="background-image-holder" style="background: url(&quot;https://mu-assets.s3.ap-south-1.amazonaws.com/new/img/banner/timeline.jpg&quot;); opacity: 1;">
              <img alt="image" src="https://mu-assets.s3.ap-south-1.amazonaws.com/new/img/banner/timeline.jpg">
          </div>
          <div class="column2-fixed-pad text-white">
            <div class="col-md-12 px-5">
              <h1>
                Timeline for studying abroad
              </h1>
              
              <hr style="border-top: 1px solid #eee">
              <p class="lead">Step 1: Select Intake</p>

              <p class="lead">
                  Step 2: Select Country
              </p>

              <p class="lead">
                  Step 3: Subscribe and get notified.
              </p>

          </div>
      </div>
      </div>
      <div class="container">
          <div class="row">
            <div class="col-lg-5 col-md-4"></div>
              <div class="col-lg-7 col-md-8">
                <h3 class="title">Let Juno Predict Best Matched foreign University for you !!
                </h3>
                <div class="usernameentry">
                  <input type="text" name="studentName" id="studentName" value="" class="form-control input-lg inputentry" placeholder="Enter your name here" required="">
              </div>
              <div class="mt-5">
              <div class="mb-5">
              <button type="button" class="btn btn-primary btn-sm" (click)="selectTab(0)">Calculator</button>
              <button type="button" class="btn btn-primary btn-sm" (click)="selectTab(1)">University Match</button>
              <button type="button" class="btn btn-primary btn-sm" (click)="selectTab(2)">Suggestions</button>
            </div>
              <tabset #staticTabs class="mt-5">
                <tab >
                  <div class="container boxed bg--secondary boxed--borde">
                    <h3 class="text-center">What course are you looking for ?</h3>
                                <div class="row">
                                  <div class="col-md-3 text-center">
                                    <figure><img src="assets/img/diploma.png" class="img-responsive" alt="Diploma"></figure>
                                    <div class="custom-control custom-radio">
                                      <span>Diploma</span>
                                      <input type="radio" class="custom-control-input" id="customRadio" name="example" value="customEx"><br>
                                      <label class="custom-control-label radio" for="customRadio"></label>
                                    </div>
                                </div>
                                <div class="col-md-3 text-center">
                                  <figure><img src="assets/img/pgdiploma.png" class="img-responsive" alt="Diploma"></figure>
                                    <div class="custom-control custom-radio">
                                      <span>PG Diploma</span>
                                      <input type="radio" class="custom-control-input" id="customRadio1" name="example" value="customEx"><br>
                                      <label class="custom-control-label radio" for="customRadio1"></label>
                                    </div>
                                </div>
                                <div class="col-md-3 text-center">
                                  <figure><img src="assets/img/bachelors.png" class="img-responsive" alt="Diploma"></figure>
                                    <div class="custom-control custom-radio">
                                      <span>Undergraduate
                                      </span>
                                      <input type="radio" class="custom-control-input" id="customRadio2" name="example" value="customEx"><br>
                                      <label class="custom-control-label radio" for="customRadio2"></label>
                                    </div>
                                </div>
                               
                                <div class="col-md-3 text-center">
                                  <figure><img src="assets/img/masters.png" class="img-responsive" alt="Diploma"></figure>
                                  <div class="custom-control custom-radio">
                                    <span>Post Graduate
                                    </span>
                                    <input type="radio" class="custom-control-input" id="customRadio3" name="example" value="customEx"><br>
                                    <label class="custom-control-label radio" for="customRadio3"></label>
                                  </div>
                                </div>
                           </div>
                      </div>
                    <button type="button" class="btn btn-primary btn-sm" (click)="selectTab(1)">Next</button>
                  
                </tab>
                <tab >
                  <div class="container boxed bg--secondary boxed--borde">
                    <h3 class="text-center">Where do you prefer to study ?</h3>
                              <div class="row">
                                <div class="col-md-4 text-center mt-3">
                                  <figure><img src="assets/img/garmeny.png" class="img-responsive" alt="Diploma"></figure>
                                  <div class="custom-control custom-radio">
                                    <span>Germany
                                    </span>
                                    <input type="radio" class="custom-control-input" id="customRadioge" name="example" value="customEx"><br>
                                    <label class="custom-control-label radioc" for="customRadioge"></label>
                                  </div>
                                </div>
                                <div class="col-md-4 text-center mt-3">
                                  <figure><img src="assets/img/New-Zealand.png" class="img-responsive" alt="Diploma"></figure>
                                  <div class="custom-control custom-radio">
                                    <span>New-Zealand
                                    </span>
                                    <input type="radio" class="custom-control-input" id="customRadionz" name="example" value="customEx"><br>
                                    <label class="custom-control-label radioc" for="customRadionz"></label>
                                  </div>
                                </div>
                                <div class="col-md-4 text-center mt-3">
                                  <figure><img src="assets/img/australia.png" class="img-responsive" alt="Diploma"></figure>
                                  <div class="custom-control custom-radio">
                                    <span>Australia
                                    </span>
                                    <input type="radio" class="custom-control-input" id="customRadioau" name="example" value="customEx"><br>
                                    <label class="custom-control-label radioc" for="customRadioau"></label>
                                  </div>
                                </div>
                                <div class="col-md-4 text-center mt-3">
                                  <figure><img src="assets/img/canada.png" class="img-responsive" alt="Diploma"></figure>
                                  <div class="custom-control custom-radio">
                                    <span>Canada
                                    </span>
                                    <input type="radio" class="custom-control-input" id="customRadioca" name="example" value="customEx"><br>
                                    <label class="custom-control-label radioc" for="customRadioca"></label>
                                  </div>
                                </div>
                                <div class="col-md-4 text-center mt-3">
                                  <figure><img src="assets/img/uk.png" class="img-responsive" alt="Diploma"></figure>
                                  <div class="custom-control custom-radio">
                                    <span >UK
                                    </span>
                                    <input type="radio" class="custom-control-input" id="customRadiouk" name="example" value="customEx"><br>
                                    <label class="custom-control-label radioc" for="customRadiouk"></label>
                                  </div>
                                </div>
                              <div class="col-md-4 text-center mt-3">
                                <figure><img src="assets/img/usa.png" class="img-responsive" alt="Diploma"></figure>
                                <div class="custom-control custom-radio">
                                  <span >US
                                  </span>
                                  <input type="radio" class="custom-control-input" id="customRadious" name="example" value="customEx"><br>
                                  <label class="custom-control-label radioc" for="customRadious"></label>
                                </div>
                              </div>
                         </div>
                    </div>
                  <button type="button" class="btn btn-primary btn-sm" (click)="selectTab(2)">Next</button>
                </tab>
                <tab >
                  <div class="container boxed bg--secondary boxed--borde">
                    <h3 class="text-center">What all exams have you given ?</h3>
                               <div class="row">
                                <div class="col-md-3 text-center mt-3">
                                  <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="customCheck3" name="example4">
                                    <label class="custom-control-label" for="customCheck3">IELTS</label>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center mt-3">
                                  <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="customCheck4" name="example3">
                                    <label class="custom-control-label" for="customCheck4">TOEFL</label>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center mt-3">
                                  <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="customCheck5" name="example2">
                                    <label class="custom-control-label" for="customCheck5">GRE</label>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center mt-3">
                                  <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="customCheck6" name="example1">
                                    <label class="custom-control-label" for="customCheck6">GMAT</label>
                                  </div>
                              </div>
                            <div class="col-md-3 text-center mt-3">
                              <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="customCheck" name="example7 ">
                                <label class="custom-control-label" for="customCheck">LAST</label>
                              </div>
                            </div>
                            <div class="col-md-3 text-center mt-3">
                              <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="customCheck1" name="example6">
                                <label class="custom-control-label" for="customCheck1">SAT</label>
                              </div>
                            </div>
                            <div class="col-md-3 text-center mt-3">
                              <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="customCheck2" name="example5">
                                <label class="custom-control-label" for="customCheck2">PTE</label>
                              </div>
                            </div>
                           
                          </div>
                      </div>
                  <button type="button" class="btn btn-primary btn-sm" (click)="selectTab(3)">Next</button>
                </tab>
                <tab >
                  <div class="container boxed bg--secondary boxed--borde">
                    <h3 class="text-center">Scores</h3>
                                <div class="row">
                                 
                                <div class="col-md-6 text-center">
                                  <ngx-slider [(value)]="value" [options]="options"></ngx-slider>
                                  <p>English Score in Class 12</p>
                                </div>
                                <div class="col-md-6 text-center">
                                  <ngx-slider [(value)]="value1" [options]="options"></ngx-slider>
                                  <p>Class 12 Percentage</p>
                                </div>
                                
                           </div>
                      </div>
                  <button type="button" class="btn btn-primary btn-sm" (click)="selectTab(4)">Next</button>
                </tab>
                <tab>
                  <div class="container boxed bg--secondary boxed--borde">
                    <h3 class="text-center">Scores</h3>
                    <div class="row">
                      <div class="col-md-6">
                        <img src="assets/img/stydin.png" class="img-responsive" alt="std">
                      </div>
                      <div class="col-md-6">
                        <p> <a href="#">Fresher</a></p> 
                        <p> <a href="#">0 - 2 years</a></p> 
                        <p> <a href="#">2 - 4 years</a></p> 
                        <p>   <a href="#"> > 4years</a></p> 
                      </div>
                     
                    </div>
                  </div>
                    <button type="button" class="btn btn-primary btn-sm" (click)="selectTab(5)">Next</button>
                </tab>
                <tab>
                  <div class="container boxed bg--secondary boxed--borde">
                    <h3 class="text-center">Preferred field to Study</h3>
                      <div class="row">
                      <div class="col-md-4 text-center mt-4">
                        <figure><img src="assets/img/sceince.png" class="img-responsive" alt="Diploma"></figure>
                        <div class="custom-control custom-radio">
                          <span >Science
                          </span>
                          <input type="radio" class="custom-control-input" id="customRadiosc" name="example" value="customEx"><br>
                          <label class="custom-control-label radioc" for="customRadiosc"></label>
                        </div>
                      </div>
                      <div class="col-md-4 text-center mt-4">
                        <figure><img src="assets/img/eng.png" class="img-responsive" alt="Diploma"></figure>
                        <div class="custom-control custom-radio">
                          <span >Engineering
                          </span>
                          <input type="radio" class="custom-control-input" id="customRadioen" name="example" value="customEx"><br>
                          <label class="custom-control-label radioc" for="customRadioen"></label>
                        </div>
                      </div>
                    <div class="col-md-4 text-center mt-4">
                      <figure><img src="assets/img/law.png" class="img-responsive" alt="Diploma"></figure>
                      <div class="custom-control custom-radio">
                        <span >LAW
                        </span>
                        <input type="radio" class="custom-control-input" id="customRadiolaw" name="example" value="customEx"><br>
                        <label class="custom-control-label radioc" for="customRadiolaw"></label>
                      </div>
                    </div>
               
                <div class="col-md-4 text-center mt-4">
                  <figure><img src="assets/img/managment.png" class="img-responsive" alt="Diploma"></figure>
                  <div class="custom-control custom-radio">
                    <span>Management
                    </span>
                    <input type="radio" class="custom-control-input" id="customRadiomg" name="example" value="customEx"><br>
                    <label class="custom-control-label radioc" for="customRadiomg"></label>
                  </div>
                </div>
                <div class="col-md-4 text-center mt-4">
                  <figure><img src="assets/img/design.png" class="img-responsive" alt="Diploma"></figure>
                  <div class="custom-control custom-radio">
                    <span >Arts, Media and Humanities
                    </span>
                    <input type="radio" class="custom-control-input" id="customRadiode" name="example" value="customEx"><br>
                    <label class="custom-control-label radioc" for="customRadiode"></label>
                  </div>
                </div>
              </div>
                  </div>
                    <button type="button" class="btn btn-primary btn-sm" (click)="selectTab(6)">Next</button>
                </tab>
                <tab>
                  <div class="container boxed bg--secondary boxed--borde">
                    <h3 class="text-center">Preferred branch for Law</h3>  
                               <div class="row ">
                                <div class="col-md-3 text-center mt-3">
                                  <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="customCheckg" name="example2">
                                    <label class="custom-control-label" for="customCheckg">Finance</label>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center mt-3">
                                  <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="customCheckm" name="example2">
                                    <label class="custom-control-label" for="customCheckm">Management</label>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center mt-3">
                                  <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="customChecklm" name="example1">
                                    <label class="custom-control-label" for="customChecklm">lmg</label>
                                  </div>
                              </div>
                              <div class="col-md-3 text-center mt-3">
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" class="custom-control-input" id="customCheckla" name="example1">
                                  <label class="custom-control-label" for="customCheckla">law</label>
                                </div>
                            </div>
                          
                          </div>
                      </div>
                  <button type="button" class="btn btn-primary btn-sm" (click)="selectTab(7)">Next</button>
                </tab>
                <tab>
                  <div class="container boxed bg--secondary boxed--borde">
                    <h3 class="text-center">Finance Plans for your study</h3>
                      <div class="row">
                      <div class="col-md-4 text-center mt-4">
                        <figure><img src="assets/img/finance.png" class="img-responsive" alt="Diploma"></figure>
                        <div class="custom-control custom-radio">
                          <span >Science
                          </span>
                          <input type="radio" class="custom-control-input" id="customRadiofn" name="example" value="customEx"><br>
                          <label class="custom-control-label radioc" for="customRadiofn"></label>
                        </div>
                      </div>
                      <div class="col-md-4 text-center mt-4">
                        <figure><img src="assets/img/loan.png" class="img-responsive" alt="Diploma"></figure>
                        <div class="custom-control custom-radio">
                          <span >Engineering
                          </span>
                          <input type="radio" class="custom-control-input" id="customRadioln" name="example" value="customEx"><br>
                          <label class="custom-control-label radioc" for="customRadioln"></label>
                        </div>
                      </div>
                    <div class="col-md-4 text-center mt-4">
                      <figure><img src="assets/img/hand.png" class="img-responsive" alt="Diploma"></figure>
                      <div class="custom-control custom-radio">
                        <span >LAW
                        </span>
                        <input type="radio" class="custom-control-input" id="customRadiosp" name="example" value="customEx"><br>
                        <label class="custom-control-label radioc" for="customRadiosp"></label>
                      </div>
                    </div>
                 </div>
                 <div class="row mt-5 text-center">
                   <div class="col-md-4"></div>
                  <div class="col-md-4">
                    <div class="form-inline">
                      <div class="form-group">
                        <label for="exampleInputName2">Others <span>(if any)</span></label>
                        <input type="text" name="studentName" id="studentName" value="" class="form-control "  required="">
                      </div>
                    </div>
                  </div>
                 </div>
                  </div>
                    <button type="button" class="btn btn-primary btn-sm" (click)="selectTab(8)">GET MY RESULT</button>
                </tab>
              </tabset> 
              </div>
            </div>
          </div>
          <!--end of row-->
      </div>
      <!--end of container-->
  </section>
</div>