<section class="cover height-50 imagebg" data-overlay="3">
    <div class="background-image-holder" style="background: url(&quot;https://mu-assets.s3.ap-south-1.amazonaws.com/new/img/banner/statement-of-purpose-sop.jpg&quot;); opacity: 1;">
        <img height="auto" width="auto" alt="image" loading="lazy" src="https://mu-assets.s3.ap-south-1.amazonaws.com/new/img/banner/statement-of-purpose-sop.jpg">
    </div>
    <div class="container pos-vertical-center">
        <div class="row">
            <div class="col-sm-12">
                <h1 class="h1--large">
                    <div class="text-uppercase" [innerHTML]="staticPage.name"></div>
                </h1>
            </div>
        </div>
        <!--end of row-->
    </div>
    <!--end of container-->
</section>



<section class="space--xxs bg--secondary">
    <div class="container">
        <div class=" row">
        <div class="col-xs-12 col-md-7">
            <div [innerHTML]="staticPage.overview"></div>
            
             
        </div>
        <div class="col-xs-12 col-md-4 col-md-offset-1">
            <!--Boxed-->
            <div class="sidebar boxed boxed--border bg--site mr-t-3">

                <div class="sidebar__widget">
                    <h5>Articles &amp; News</h5>
                    <ul id="business-news" class="sidebar-article-lh">

                    <li> <a href="https://meetuniversity.com/blog/types-of-ielts-test/"> - Types of IELTS Test</a> </li><li> <a href="https://meetuniversity.com/blog/ielts-reading-practice-test-ielts-general/"> - IELTS Reading Practice Test- IELTS General</a> </li><li> <a href="https://meetuniversity.com/blog/ielts-writing-practice-test-ielts-general/"> - IELTS Writing Practice Test- IELTS General</a> </li></ul>
                </div>
                <!--end widget-->
                <div class="sidebar__widget">
                    <h5>Tools</h5>
                    <ul>
                        <li>
                            <a rel="noopener" href="/timeline">Timeline Alerts</a>
                        </li>
                        <li>
                            <a rel="noopener" routerLink="/ask-juno">University Score Predictor</a>
                        </li>
                    </ul>
                </div>
                <!--end widget-->
                <div class="sidebar__widget">
                    <h5>Quick Tip</h5>
                    <p>
                        Our new digital products will take your workflow to all-new levels of high productivity. We know you'll find everything you
                        need - and more! Start building with MeetUniversity.
                    </p>
                </div>
            </div>

            <!--Boxed-->
            <div class="boxed-ad"  style="background-image: url(assets/img/country-ad-backdrop.jpg);
            margin-top: 13px;">
                <div class="title color--white">WANT TO
                    <br>STUDY ABROAD?</div>
                <a rel="noopener" routerLink="/student-guide/uk" class="btn btn-default btn-orrange">See Country Guides</a>
                <img src="assets/img/student.png"  class="student"/>
            </div>
        </div>
    </div>
</div>
</section>