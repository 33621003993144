<div class="main-container">
  <section class="unpad--bottom unpad--top text-center bg--secondary">
		<div class="container">
			<div class="row">
				<div class="col-md-12 col-centered mt--3 mr-b-3">
          <!-- <h6 class="type--uppercase"></h6> -->
          <div class="typed-headline">
            <span class="h1 inline-block">Empowering students </span>
            <span class="h1 inline-block typed-text typed-text--cursor color--primary"
              data-typed-strings="to apply to the desired university.">to apply to the desired university.</span>
          </div>
          <!-- <p class="lead">
                    </p> -->
          <div class="searchsection">
            <form action="/search" class="form--horizontal row m-0">
              <div class="col-md-2"></div>
              <div class="col-md-6 nopaading">
                <input id="t1" class="validate-required" type="text" name="q" placeholder="Search Courses, Universities"
                  autocomplete="off" #searchText>
                <label for="t1">Search Courses, Universities</label>
              </div>
              <div class="col-md-2 nopaading">
                <button type="submit" class="btn btn--primary type--uppercase" (click)="searchByKeyWord(searchText.value)" >Search</button>
              </div>
              <div class="col-md-2"></div>
            </form>
          </div>
          <!-- <a class="btn btn--primary type--uppercase inner-link" href="#demos">
                        <span class="btn__text">
                            Explore Demos
                        </span>
                    </a> -->
        </div>
				<div class="col-sm-12 text-center">
					<img height="auto" width="auto" alt="image" src="assets/img/banner/avatar-group.jpg">
				</div>
			</div>
			<!--end of row-->
		</div>
		<!--end of container-->
	</section>


  <section class="text-center-xs bordernone applyhome">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-sm-6">
          <h2>Applying to universities shouldn’t be this hard</h2>
          <img height="auto" width="auto" alt="image" loading="lazy" src="assets/img/confusion-choice.webp" />
        </div>

        <div class="col-md-1 col-sm-1"></div>

        <div class="col-md-5 col-sm-5">
          <img height="auto" width="auto" alt="image" loading="lazy" src="assets/img/clear-hai-boss.jpg" />
          <form id="home-verification" class="homesearch form-submit" data-check="otp" data-call="/otp/generate"
            novalidate="novalidate">
            <div class="row maingetstarted">
              <h4>
                With MeetUniversity it’s simple to apply to various
                universities with a single form
              </h4>
              <!-- <div class="col-md-8 col-sm-8 nopaading">
                <span id="messageValidPhone"></span>

                <input name="phone" id="phone-number" required="" placeholder="Enter Your Number" type="number"
                  aria-required="true" autocomplete="off" class="" />
                <label for="phone-number"> Phone Number </label>
              </div> -->
              <div class="col-md-4 col-sm-4 nopaading">
                <button type="submit" class="btn btn--primary getstartedbtnclass" routerLink="/ask-expert" >
                  Get Started
                </button>
              </div>
            </div>
          </form>

          <form id="otp-verify" class="homesearch form-submit" data-check="mverify" data-call="/otp/verify"
            novalidate="novalidate">
            <div class="getstarted" style="display: none">
              <h4>Phone Verification</h4>
              <p>
                One Time Password (OTP) has been sent to your mobile ******<span id="phone-check">3867</span>, please
                enter the same here.
              </p>
              <div class="row">
                <input id="m-verify" class="required" name="phone" type="hidden" aria-required="true"
                  autocomplete="off" />
                <div class="col-md-8 col-sm-8">
                  <input class="validate-required unmarg--top" name="token" placeholder="Enter OTP" type="text"
                    required="" aria-required="true" autocomplete="off" />
                </div>
                <div class="col-md-4 col-sm-4">
                  <button class="btn btn--sm btn--primary type--uppercase" type="submit">
                    Verify Your Mobile
                  </button>
                </div>
              </div>
              <div class="phonemsg mt--1">
                <b>
                  Didnt get one?
                  <a rel="noopener" href="javascript:void(0);"
                    onclick="return show_hide('maingetstarted','getstarted');">Resend OTP</a></b><br />
                Verify with a different mobile number?
                <a rel="noopener" href="javascript:void(0);"
                  onclick="return show_hide('maingetstarted','getstarted');">Change Mobile Number</a>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!--end of container-->
    </div>
  </section>

  <section class="space--sm">
    <div class="container">
      <div class="row">
        <div class="col-sm-10 col-md-6">
          <h2>Let's see how it works?</h2>
          <span class="h4">
            We help you search, discover and apply to desired university with
            ease.
          </span>
        </div>
      </div>
      <!--end of row-->
    </div>
    <!--end of container-->
  </section>

  <section class="bordernone">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <ol class="process-3">
            <li class="process_item">
              <div class="process__number">
                <span>1</span>
              </div>
              <div class="process__body">
                <h4>Search</h4>
                <p>
                  Search from our extensive course and university listings for a
                  course that suits you best.
                </p>
              </div>
            </li>
            <li class="process_item">
              <div class="process__number">
                <span>2</span>
              </div>
              <div class="process__body">
                <h4>Discover</h4>
                <p>
                  Set Filters according to your requirements and narrow down to
                  speciﬁc courses and universities to suit your needs.
                </p>
              </div>
            </li>
            <li class="process_item">
              <div class="process__number">
                <span>3</span>
              </div>
              <div class="process__body">
                <h4>Apply</h4>
                <p>
                  Upon narrowing down to the required Course and University,
                  simply upload your documents and apply.
                </p>
              </div>
            </li>
          </ol>
        </div>
        <div class="col-md-6 col-12">
          <div class="video-cover border--round">
            <div class="background-image-holder" style="background: url('assets/img/intro.webp'); opacity: 1">
              <img alt="image" src="assets/img/intro.webp" />
            </div>
            <div class="video-play-icon"></div>
            <iframe data-src="https://www.youtube.com/embed/iMcpEvUf2oY?autoplay=1"
              allowfullscreen="allowfullscreen"></iframe>
          </div>
          <!--end video cover-->
          <span>24283 Students have applied on Meet University and still counting
            <a rel="noopener" routerLink='/ask-expert'>Try Now</a></span>
        </div>
      </div>
      <!--end of row-->
    </div>
    <!--end of container-->
  </section>

  <section class="feature-large bg--secondary">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="chat-box">
            <video height="400" width="100%" loop="" autoplay="" muted="" id="vid">
              <source src="assets/img/chat.mp4" type="video/webm" />
            </video>
          </div>
        </div>
        <div class="col-md-6">
          <div class="switchable__text">
            <h2>Search Assistance</h2>
            <p class="lead">
              Get instant advice on course criteria, university requirements ,
              scholarship updates &amp; acceptance. Know your university match
              in seconds. Speak to our experts for unbiased university selection
              advice
            </p>
            <a rel="noopener" routerLink="/ask-juno">Know More »</a>
          </div>
        </div>
      </div>
      <!--end of row-->
    </div>
    <!--end of container-->
  </section>

  <section class="feature-large bg--secondary">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="chat-box">
            <video height="400" width="100%" loop="" autoplay="" muted="" id="vid">
              <source src="assets/img/business_fella.webm" type="video/webm" />
            </video>
          </div>
        </div>
        <div class="col-md-6">
          <div class="switchable__text">
            <h2>Admissions made easy</h2>
            <p class="lead">
              Search, Shortlist and apply to your dream university with ease.
              End to end technology based application assistance backed by best
              study abroad expert advice
            </p>
            <a rel="noopener" href="#" routerLink='/ask-expert'>Know More »</a>
          </div>
        </div>
      </div>
      <!--end of row-->
    </div>
    <!--end of container-->
  </section>

  <section class="bg--dark bg--primary text-center applyinghomebox">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <h2 class="">WHY CHOOSE MEET UNIVERSITY</h2>
          <h4 class="marginbtm">
            Nothing in Higher Education decision making changed, so we decided
            to be the change ourselves.<br />
            400K students counseled | 20K+ connected | 2500+ Universities
          </h4>
        </div>
      </div>
      <!--end of row-->

      <div class="row mt-5 process-2">
        <div class="col-sm-4">
          <div class="process__item">
            <img height="auto" width="auto" alt="image" loading="lazy" src="assets/img/icon-document.svg" />
            <h4>Simply upload documents to your proﬁle</h4>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="process__item">
            <img height="auto" width="auto" alt="image" loading="lazy" src="assets/img/icon-shortlist.svg" />
            <h4>Shortlist the desired courses and universities</h4>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="process__item">
            <img height="auto" width="auto" alt="image" loading="lazy" src="assets/img/icon-apply.svg" />
            <h4>Apply to universities by just ﬁlling a single form</h4>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-5"></div>
        <div class="col-md-2">
          <a class="btn"  routerLink='/ask-expert'>
            <span class="btn__text">Apply</span>
          </a>
        </div>
      </div>
    </div>
    <!--end of container-->
  </section>


  <app-upcoming-events></app-upcoming-events>

</div>